<script setup>
import { ref, defineProps, computed, onMounted, watch, defineExpose } from 'vue';

import StatusGrid from '../StatusGrid.vue';
import { GrupoRiscosLabelEnum } from '../../../../enums/GrupoRiscos';
import { StatusGroGrupoRiscosElaboracaoEnum } from '../../../../enums/StatusGroGrupoRiscosElaboracao';
import { styleMapStatusGrupoRiscos } from '../../../../enums/StatusGroGrupoRiscosElaboracao';
import { useMoment } from '../../../../composables/useMoment';

import { helpers, requiredIf } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';

const { formatDateTz, formatDateBr } = useMoment();

const props = defineProps({
    grupoRisco: Number,
    nomeRisco: String,
    dataInicio: String,
    dataFim: String,
    exposicaoAtiva: Boolean,
    mediaGeral: Number,
    risco: Object
});

const statusGrupoRiscosOpcoes = ref([]);
const labelEnumRisco = GrupoRiscosLabelEnum;
const propsRef = ref(props);

const payload = ref({
    dataFim: null,
    exposicaoAtiva: false
});

const dataInicioFormat = computed(() => {
    const exibir = formatDateTz(propsRef.value.dataInicio);
    const minDate = formatDateBr(propsRef.value.dataInicio);
    return {
        exibir,
        minDate
    };
});

watch(
    () => payload.value.exposicaoAtiva,
    (value) => {
        if (value) {
            payload.value.dataFim = null;
        }
    }
);

watch(
    () => props.dataFim,
    (value) => {
        if (value) payload.value.dataFim = new Date(value);
    }
);

watch(
    () => props.exposicaoAtiva,
    (value) => {
        payload.value.exposicaoAtiva = value;
    }
);

const desabilitarDataFinal = computed(() => !!payload.value.exposicaoAtiva || props.dataFim);

const rules = {
    dataFim: {
        required: helpers.withMessage(
            'A Data final é obrigatória, caso não seja uma exposição ativa.',
            requiredIf(() => payload.value.exposicaoAtiva === false)
        ),
        lazy: true
    }
};

const validExposicao = useVuelidate(rules, payload.value);

const getStatusGrupoRiscosOpcoes = () => {
    statusGrupoRiscosOpcoes.value = Object.keys(StatusGroGrupoRiscosElaboracaoEnum).map(function (type) {
        return {
            label: `${StatusGroGrupoRiscosElaboracaoEnum[type]}`,
            value: `${type}`,
            style: styleMapStatusGrupoRiscos[StatusGroGrupoRiscosElaboracaoEnum[type]]
        };
    });
};

function getRiscosComTodasAsConfigs(grupoRisco) {
    return statusGrupoRiscosOpcoes.value.find((p) => p.label === grupoRisco);
}

defineExpose({ validExposicao, payload });

onMounted(() => {
    getStatusGrupoRiscosOpcoes();
});

const customLabelUnidadeMedida = computed(() => {
    if (!props.risco.unidadeMedida.simbolo) {
        return props.risco.unidadeMedida.descricao;
    }

    return `${props.risco.unidadeMedida.simbolo}`;
});
</script>
<template>
    <div class="grid flex aling-items-center mb-5">
        <div class="flex align-items-center justify-content-center mt-5 col-1">
            <StatusGrid class="mr-4" v-if="props.grupoRisco" :status="getRiscosComTodasAsConfigs(labelEnumRisco.get(propsRef.grupoRisco))" />
        </div>
        <div class="flex flex-column col">
            <label class="mb-2" for="descricaoRisco">Fator de risco</label>
            <InputText disabled class="w-full" id="descricaoRisco" v-model="propsRef.nomeRisco" v-if="propsRef.nomeRisco" />
        </div>
        <div class="flex flex-column col">
            <label class="mb-1" for="dataInicio">Data inicial</label>
            <InputText disabled id="dataInicio" v-model="dataInicioFormat.exibir" v-if="propsRef.dataInicio" class="w-full mt-1" />
        </div>
        <div class="flex flex-column col">
            <label class="mb-1" for="dataFim">Data final</label>

            <Calendar
                selectionMode="single"
                :disabled="desabilitarDataFinal"
                id="dataFim"
                :minDate="dataInicioFormat.minDate"
                v-model="validExposicao.dataFim.$model"
                :disabledDays="[0, 6]"
                class="w-full mt-1"
                dateFormat="dd/mm/yy"
                :class="{ 'p-invalid': validExposicao.$errors.length > 0 }"
            />

            <small v-if="validExposicao.dataFim.$errors.length" class="p-error">
                {{ validExposicao.dataFim.$errors[0].$message }}
            </small>
        </div>
        <div class="flex align-items-center col mt-4">
            <Checkbox v-model="payload.exposicaoAtiva" inputId="exposicaoAtiva" name="exposicaoAtiva" :value="true" binary />
            <label for="exposicaoAtiva" class="ml-2"> Exposição ativa </label>
        </div>
        <div class="flex flex-column col">
            <label class="mb-1" for="dataInicio">Medição considerada</label>
            <div>
                {{
                    props.mediaGeral
                        ? props.mediaGeral.toFixed(2) + ' ' + `${props.risco.unidadeMedida?.id ? customLabelUnidadeMedida : ''}`
                        : 'Nenhuma medição anterior'
                }}
            </div>
        </div>
    </div>
</template>
